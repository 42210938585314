import { FC } from "react";
import { dashPink, rectBig, rectSmall, dashPinkMedium } from "../../common/svg";

const Team: FC = () => {
  return (
    <div className="team-membership relative">
      <div className="team bg-white pt-[75px] pb-[88px] px-24 w-full">
        <div className="team-nav flex flex-col items-center ">
          <h4 className="font-roxbor font-semibold text-[24px] md:text-[28.5px] text-grey-400">
            Our Team
          </h4>
          <span
            className="-mt-1 hidden md:block"
            dangerouslySetInnerHTML={{ __html: dashPink }}
          />
          <span
            className="-mt-1 block md:hidden "
            dangerouslySetInnerHTML={{ __html: dashPinkMedium }}
          />
        </div>

        <div className="team-content  items-center justify-center mt-9 flex ">
          <div className="content-member  w-[34%] mr-2 relative">
            <img
              className="rounded-b-[15px] "
              src="/assets/Ajiri.png"
              alt="Ajiri Omafokpe"
            />

            <div className="content-detail absolute bg-darker-linear w-full px-4 py-3.5 -mt-[63px] flex items-center justify-between rounded-b-[15px]">
              <div className="font-inter text-gray-light">
                <h5 className="font-extrabold text-[16px]">Ajiri Omafokpe</h5>
                <p className="font-[700] text-[12.5px]">Co-Founder</p>
              </div>

              <a
                href="https://www.linkedin.com/in/ajiri-omafokpe/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/linkedIn.svg" alt="linkedIn Profile" />
              </a>
            </div>
          </div>

          <div className="content-member w-[100%] md:w-[34%] ml-0 md:ml-1.5 relative">
            <img
              className="rounded-b-[15px]"
              src="/assets/Ifeanyi.png"
              alt="Micheal Ifeanyi"
            />

            <div className="content-detail absolute  bg-darker-linear w-full px-4 py-3.5 -mt-[63px] flex items-center justify-between rounded-b-[15px]">
              <div className="font-inter text-gray-light">
                <h5 className="font-extrabold text-[16px]">Micheal Ifeanyi</h5>
                <p className="font-[700] text-[12.5px]">Co-Founder</p>
              </div>

              <a
                href="https://www.linkedin.com/in/michaelifeanyi/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/assets/linkedIn.svg" alt="linkedIn Profile" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        id="membership-perks"
        className="membership bg-grey-400 pt-44 pb-20 px-24 w-full"
      >
        <div className="membership-benf rounded-[30px] bg-white w-full p-10 h-[18rem] relative flex justify-between">
          <div className="membership-left">
            <div className="backg-img z-10 relative ml-9  hidden md:block -mt-3">
              <span
                className="absolute ml-[40px]"
                dangerouslySetInnerHTML={{ __html: rectSmall }}
              />
              <span
                className="mt-[45px] -ml-[103px] absolute"
                dangerouslySetInnerHTML={{ __html: rectBig }}
              />
            </div>
            <div className="membership-top absolute z-30 bg-primary-500 rounded-t-[40px] h-[24rem] w-[375px] px-9 pb-5 pt-7 -top-24 ml-[103px] flex flex-col justify-center">
              <h4 className="font-roxbor font-[600] text-white text-[22px] md:text-[30px] text-center md:text-start">
                Membership Benefits
              </h4>
              <div className="flex items-center gap-x-3 mt-3.5">
                <img
                  className="w-[15px]"
                  src="/assets/half-logo-pink.svg"
                  alt="tribe's logo"
                />
                <p className="font-inter font-medium text-white text-[11px]">
                  Access to exclusive content and resources
                </p>
              </div>

              <div className="flex items-center gap-x-3  mt-2.5">
                <img
                  className="w-[15px]"
                  src="/assets/half-logo-pink.svg"
                  alt="tribe's logo"
                />
                <p className="font-inter font-medium text-white text-[11px]">
                  Priority networking and mentorship opportunities
                </p>
              </div>

              <div className="flex items-center gap-x-3 mt-2.5">
                <img
                  className="w-[15px]"
                  src="/assets/half-logo-pink.svg"
                  alt="tribe's logo"
                />
                <p className="font-inter font-medium text-white text-[11px]">
                  Discounts on events and workshops
                </p>
              </div>

              <div className="flex items-center gap-x-3 mt-2.5">
                <img
                  className="w-[15px]"
                  src="/assets/half-logo-pink.svg"
                  alt="tribe's logo"
                />
                <p className="font-inter font-medium text-white text-[11px]">
                  Premium support and advanced learning resources
                </p>
              </div>
            </div>
          </div>

          <div className="membership-right w-[30%]">
            <button
              type="button"
              className="membership-button w-[320px] -ml-[6.8rem] mt-[98px]"
            >
              <img
                className="w-full hover:opacity-80 "
                src="/assets/join-us.svg"
                alt="join us button"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
