import { FC } from "react";

const Footer: FC = () => {
  return (
    <div className="footer relative">
      <div className="footer-content bg-secondary-100 py-8 px-24 w-full">
        <div className="footer-top flex items-center justify-between pb-4 border-b-[0.5px] border-grey-400 px-1.5">
          <img src="/assets/logoBlack.svg" alt="Builder Tribe Logo In Black" />

         <div className="quick-links flex items-center gap-x-6 font-normal text-[11px] text-grey-400 font-inter">
           <a href="https://docs.google.com/forms/d/e/1FAIpQLSd8C998ZzpsoGTo5mbCl8gOXsdVLhX035V0_OflwrQmprx2hg/viewform" className="hover:border-b border-grey-400">
           Join Our Community
           </a>
           <a href="https://www.youtube.com/@builderstribehq" target="_blank" rel="noreferrer" className="hover:border-b border-grey-400">
           Subscribe to Our YouTube
           </a>
           <a href="#membership-perks" className="hover:border-b border-grey-400">
           Membership Perks
           </a>
           <a href="#upcoming-activities" className="hover:border-b border-grey-400">
           Upcoming Activities
           </a>
           <a href="#about" className="hover:border-b border-grey-400">
           Our Why
           </a>
           <a href="#team" className="hover:border-b border-grey-400">
           Our Team
           </a>
         </div>
        </div>

        <div className="footer-bottom flex items-center justify-between pt-4">
          <div className="footer-icons flex items-center gap-x-3">
            <a href="https://x.com/builderstribehq" target="_blank" rel="noreferrer">
              <img className="w-[30.5px]" src="/assets/Twitter.svg" alt="Twitter Link" />
            </a>

            <a href="mailto:builderstribehq@gmail.com" target="_blank" rel="noreferrer">
              <img className="w-[30.5px]" src="/assets/Mail.svg" alt="Our Mailing Address Link" />
            </a>

            <a href="https://www.linkedin.com/company/builderstribe/" target="_blank" rel="noreferrer">
              <img className="w-[30.5px]" src="/assets/LinkedInBlack.svg" alt="LinkedIn Link" />
            </a>

            <a href="https://www.instagram.com/builderstribehq?igsh=MWQ5eGtnM3BpZTZkbw==" target="_blank" rel="noreferrer">
              <img className="w-[30.5px]" src="/assets/Instagram.svg" alt="Instagram Link" />
            </a>

            <a href="https://www.youtube.com/@builderstribehq" target="_blank" rel="noreferrer">
              <img className="w-[30.5px]" src="/assets/Youtube.svg" alt="Youtube Link" />
            </a>
          </div>
          
          <p className="text-grey-300 font-inter font-medium text-[10.5px]">Builderstribe © 2024 all rights reserved</p>
        </div>
    </div>
    </div>
  );
};

export default Footer;
