import { FC } from "react";
import {
  dashPink,
  dashYellow,
  dashPinkMedium,
  dashYellowMedium,
} from "../../common/svg";

const About: FC = () => {
  return (
    <div className="about relative">
      <div className="about-drives bg-primary-200 py-16 px-24 w-full">
        <div className="drives-nav flex flex-col items-center">
          <h4 className="font-roxbor font-semibold text-[24px] md:text-[28.5px] text-grey-400">
            What Drives Us
          </h4>
          <span
            className="-mt-1 hidden md:block"
            dangerouslySetInnerHTML={{ __html: dashPink }}
          />
          <span
            className="-mt-1 block md:hidden "
            dangerouslySetInnerHTML={{ __html: dashPinkMedium }}
          />
        </div>

        <div className="drives-content-top flex items-center justify-between mt-8">
          <div className="driv-imgx w-[60.5%]">
            <img
              className="driv-imgs"
              src="/assets/drives-us.png"
              alt="People smiling taking self"
            />
          </div>

          <div className="visin-cont w-[37.5%]">
            <div className="our-vission bg-secondary-100 py-[37px] rounded-tl-[50px] rounded-tr-[50px] md:rounded-tl-[0px]">
              <p className="text-center font-roxbor font-[700]  text-grey-400 text-[24px] md:text-[26px]    ">
                Our Vision
              </p>
            </div>
            <div className="vission-content bg-primary-400 py-8 md:py-14 px-[2rem] md:px-[4.2rem] mt-6">
              <p className=" text-start font-inter font-normal md:text-[21.5px] text-[18px] text-white leading-[30px]">
                Connecting talent, founders, and investors to build a thriving
                tech ecosystem in the MENA region.
              </p>
            </div>
          </div>
        </div>

        <div className="drives-content-bottom flex items-center justify-between mt-5">
          <div className="our-vission bg-grey-400 rounded-bl-[0px] md:rounded-bl-[50px]  py-10 md:py-14   w-[100%] md:w-[37.5%]">
            <p className="text-center font-roxbor font-[700] text-[24px] md:text-[28px] text-white">
              Our Mission
            </p>
          </div>

          <div className="mission-content bg-secondary-200 rounded-br-[50px] rounded-bl-[50px] md:rounded-bl-[0px] py-[1.9rem] md:py-[2.9rem] w-[100%] md:w-[60.5%]  px-[2rem] md:px-[5.2rem]">
            <p className="text-start font-inter font-normal text-[18px] md:text-[21px] text-grey-400 leading-[30px]">
              To foster collaboration and innovation, empowering startups to
              scale and succeed across the MENA region.
            </p>
          </div>
        </div>
      </div>

      <div className="about-cores w-full py-[4.3rem] px-24 bg-secondary-500">
        <div className="cores-nav flex flex-col items-center">
          <h4 className="team-nav font-roxbor font-[500] text-[24px] md:text-[28.5px] text-white">
            Our Core Values
          </h4>
          <span
            className="-mt-1 hidden md:block"
            dangerouslySetInnerHTML={{ __html: dashYellow }}
          />
          <span
            className="-mt-1 block md:hidden"
            dangerouslySetInnerHTML={{ __html: dashYellowMedium }}
          />
        </div>

        <div className="cores-content w-[32rem] mt-9 mx-auto">
          <img
            className="w-[100%] block md:hidden "
            src="/assets/core-value1.png"
            alt="Our core values"
          />

          {/*  Image that only shows on medium screens and above  */}
          <img
            className="w-full hidden md:block"
            src="/assets/core-values.png"
            alt="Our core values for medium screens"
          />
        </div>
      </div>

      <div className="about-we-do bg-primary-200 pt-16 pb-20 px-24 w-full">
        <div className="we-do-nav team-nav flex flex-col items-center">
          <h4 className="font-roxbor font-semibold  text-grey-400 text-[24px] md:text-[28.5px]  ">
            What We Do
          </h4>

          <span
            className="-mt-1 hidden md:block"
            dangerouslySetInnerHTML={{ __html: dashPink }}
          />
          <span
            className="-mt-1 block md:hidden "
            dangerouslySetInnerHTML={{ __html: dashPinkMedium }}
          />
        </div>

        <div className="we-do-content flex items-center justify-between w-[68.2%] mx-auto mt-12">
          <div className="do-content-left flex flex-col items-center gap-y-4">
            <div className="wedo-wrappr px-5 py-[21px] rounded-[24px] bg-grey-400 w-[26rem] ">
              <h5 className="font-inter font-extrabold text-[15px] text-white">
                Telling Founder Stories
              </h5>
              <p className="font-inter font-medium text-[12.5px] text-white mt-1.5">
                We share inspirational stories and insights from tech founders
                in the MENA region, providing valuable lessons and inspiration
                to our community.
              </p>
              <img
                className="mt-6 w-[112px]"
                src="/assets/logo-white.svg"
                alt="tribe's white logo"
              />
            </div>

            <div className="wedo-wrappr px-5 py-[21px] rounded-[24px] bg-grey-400 w-[26rem]">
              <h5 className="font-inter font-extrabold text-[15px] text-white">
                Bringing People Together
              </h5>
              <p className="font-inter font-medium text-[12.5px] text-white mt-1.5">
                We organize events and platforms to connect tech talent with
                founders, co-founders, and investors, facilitating valuable
                collaborations and partnerships.
              </p>
              <img
                className="mt-6 w-[135px] float-left md:float-right"
                src="/assets/logo-pink.svg"
                alt="tribe's pink logo"
              />
            </div>

            <div className="wedo-wrapprx suppot-com px-5 py-[21px] rounded-[24px] bg-grey-400 w-[26rem]">
              <img
                className="w-[19px]"
                src="/assets/half-black-logo.svg"
                alt="tribe's black logo"
              />
              <h5 className="mt-4 font-inter font-extrabold text-[15px] text-white">
                Building a Supportive Community
              </h5>
              <p className="font-inter font-medium text-[12.5px] text-white mt-1.5">
                We create a supportive and collaborative environment for the
                tech community, fostering a culture of mutual support and
                growth.
              </p>
            </div>
          </div>

          <div className="do-content-right flex flex-col items-center gap-y-4">
            <div className="wedo-wrappr px-5 py-[21px] rounded-[24px] bg-grey-400 w-[26rem]">
              <img
                className="w-[19px]"
                src="/assets/half-black-logo.svg"
                alt="tribe's black logo"
              />
              <h5 className="mt-4 font-inter font-extrabold text-[15px] text-white">
                Guiding Your Journey
              </h5>
              <p className="font-inter font-medium text-[12.5px] text-white mt-1.5">
                We offer guidance and support to tech builders and startups
                through our mentorship program, helping them navigate challenges
                and achieve their goals.
              </p>
            </div>

            <div className="wedo-wrappr px-5 py-[21px] rounded-[24px] bg-grey-400 w-[26rem]">
              <img
                className="w-[19px]"
                src="/assets/half-black-logo.svg"
                alt="tribe's black logo"
              />
              <h5 className="mt-4 font-inter font-extrabold text-[15px] text-white">
                Connecting Ideas with Funding
              </h5>
              <p className="font-inter font-medium text-[12.5px] text-white mt-1.5">
                We help startups connect with potential investors, bridging the
                gap between innovative ideas and the funding needed to bring
                them to life.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
