import { FC, useState, useEffect } from "react";
const Navbar: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isMenuOpen]);

  return (
    <nav className="navbar flex items-center justify-between px-4 py-4 md:px-12 md:py-6 lg:px-24 lg:py-8">
      <a className="navLogo w-[160px] md:w-[130px]" href="/">
        <img src="/logo.svg" alt="Builder tribe logo" />
      </a>

      <ul className="navLinks hidden xl:flex justify-between w-[380px]">
        <li className=" transition-all font-inter font-normal text-[16px] text-grey-400 hover:text-primary-500 hover:border-b-2 hover:border-b-primary-500 hover:font-medium">
          <a href="#home">Home</a>
        </li>
        <li className=" transition-all font-inter font-normal text-[16px] text-grey-400 hover:text-primary-500 hover:border-b-2 hover:border-b-primary-500 hover:font-medium">
          <a href="#about-us">About Us</a>
        </li>
        <li className=" transition-all font-inter font-normal text-[16px] text-grey-400 hover:text-primary-500 hover:border-b-2 hover:border-b-primary-500 hover:font-medium">
          <a href="#our-team">Our Team</a>
        </li>
        <li className=" transition-all font-inter font-normal text-[16px] text-grey-400 hover:text-primary-500 hover:border-b-2 hover:border-b-primary-500 hover:font-medium">
          <a href="#contact-us">Contact Us</a>
        </li>
      </ul>

      <div className="navBtn hidden md:flex">
        <button
          type="button"
          className="py-2.5 px-6 bg-primary-500 text-white text-[14px] font-inter font-normal rounded-lg "
        >
          Join our community
        </button>
      </div>

      {/* Toggle menu button */}
      <div
        className="cursor-pointer xl:hidden block"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {isMenuOpen ? (
          // Close Icon
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            style={{
              fill: "#141B34",
              transition: "transform 0.3s ease",
            }}
          >
            <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
          </svg>
        ) : (
          // Hamburger Icon
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            style={{
              fill: "#141B34",
              transition: "transform 0.3s ease",
            }}
          >
            <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z" />
          </svg>
        )}
      </div>

      {/* MOBILE MENU */}
      <div
        className={`dropdown-nav absolute xl:hidden left-0 right-0 h-[calc(50vh-80px)] bg-white flex flex-col text-end gap-6 p-5 font-semibold text-lg z-50 transition-all duration-500 ease-in-out ${
          isMenuOpen
            ? "top-[80px] opacity-100 translate-y-0"
            : "-top-full opacity-0"
        }`}
      >
        <li className=" transition-all font-inter font-normal text-[14px] text-grey-400  hover:font-medium">
          <a
            className="inline-block border-b-2 border-transparent hover:border-primary-500"
            href="#home"
            onClick={() => setIsMenuOpen(false)}
          >
            Home
          </a>
        </li>
        <li className=" transition-all font-inter font-normal text-[14px] text-grey-400 hover:font-medium">
          <a
            className="inline-block border-b-2 border-transparent hover:border-primary-500"
            href="#about-us"
            onClick={() => setIsMenuOpen(false)}
          >
            About Us
          </a>
        </li>
        <li className=" transition-all font-inter font-normal text-[14px] text-grey-400 hover:font-medium">
          <a
            className="inline-block border-b-2 border-transparent hover:border-primary-500"
            href="#our-team"
            onClick={() => setIsMenuOpen(false)}
          >
            Our Team
          </a>
        </li>
        <li className=" transition-all font-inter font-normal text-[14px] text-grey-400 hover:font-medium">
          <a
            className="inline-block border-b-2 border-transparent hover:border-primary-500"
            href="#contact-us"
            onClick={() => setIsMenuOpen(false)}
          >
            Contact Us
          </a>
        </li>

        <div
          className="navBtn md:hidden sm:flex "
          onClick={() => setIsMenuOpen(false)}
        >
          <button
            type="button"
            className="mobile-Navbtn py-2.5 px-4 bg-primary-500 text-white text-[12px]  font-inter font-normal rounded-lg "
          >
            Join our community
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
