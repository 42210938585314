import Navbar from './components/navbar';
import Home from './components/home';
import About from './components/about';
import Team from './components/team';
import Contact from './components/contact';
import ReactGA from "react-ga4";
import { useEffect } from 'react';
import Footer from './components/footer';

function App() {

  const TRACKING_ID = "G-PKQJK8V23Y";
useEffect(() => {
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({ hitType: 'pageview', page: '/' }); // Initial page load
}, []);

  return (
    <div className="App">
       <section className="navbar">
         <Navbar />
       </section>

       <section className="home" id="home">
         <Home />
       </section>

       <section className="about" id="about-us">
         <About />
       </section>

       <section className="team" id="our-team">
         <Team />
       </section>

       <section className="contact" id="contact-us">
         <Contact />
       </section>

       <section className="footer" id="footer">
         <Footer />
       </section>
    </div>
  );
}

export default App;
